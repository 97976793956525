<script>
import { CUSTOMERS_VIEW } from "@/graphql/customer/queries.gql";
import CustomerListItem from "./CustomerListItem.vue";

import {
  SORT_ORDER,
  VxDataList,
  PaginationOptionsUtils,
  ServerPaginationUtils,
} from "@/core-ui";

export default {
  name: "CustomersListView",
  title: "Customers",
  components: {
    VxDataList,
    CustomerListItem,
  },
  props: {
    parsedFilter: {
      type: Object,
      default: undefined,
    },
    storeId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    const defaultPaginationOptions =
      PaginationOptionsUtils.buildDefaultPaginationOptions();
    const defaultSortOption = PaginationOptionsUtils.buildDefaultSortOptions({
      sortBy: "updatedAt",
      sortOrder: SORT_ORDER.DESC,
    });

    return {
      title: "Customers",
      // TODO - see if this is needed as init data or not
      customers: [],
      customersLoading: 0,
      paginationOptions: defaultPaginationOptions,
      sortOptions: defaultSortOption,
      serverPaginationRequest:
        PaginationOptionsUtils.buildDefaultServerPaginationRequest(
          defaultPaginationOptions,
          defaultSortOption
        ),
      advancedLoading: false,
    };
  },
  apollo: {
    customers: {
      loadingKey: "customersLoading",
      query: CUSTOMERS_VIEW,
      variables() {
        return this.queryVariables;
      },
      fetchPolicy: "cache-and-network", // TODO - check about fetch policy everywhere
      update({ customers = { edges: [] } }) {
        ServerPaginationUtils.updateOptionsFromServerData(
          this.paginationOptions,
          customers
        );

        return customers.edges.map(({ node }) => node);
      },
    },
  },
  computed: {
    isSmallScreen() {
      // TODO - put this in some common lib
      return this.$vuetify.breakpoint.smAndDown;
    },
    headers() {
      return [
        {
          propertyPath: "firstName",
          label: "Name",
          sortable: true,
          cssClass: "name-cell",
        },
        {
          propertyPath: "updatedAt",
          label: "Last Updated",
          sortable: true,
          propertyName: "updatedAt",
          cssClass: "date-cell",
        },
        {
          propertyPath: "phoneNumber",
          label: "Phone #",
          sortable: true,
          cssClass: "phone-cell",
        },
        {
          propertyPath: "numBuys",
          label: "# Buys",
          sortable: true,
          align: "right",
          cssClass: "number-buys-cell",
        },
        {
          propertyPath: "totalPurchamt",
          label: "Total Sales",
          sortable: true,
          align: "right",
          cssClass: "total-amount-cell",
        },
      ];
    },
    queryVariables() {
      let storeId = undefined;
      if (!!this.parsedFilter && this.parsedFilter.isAdvanced) {
        storeId = this.parsedFilter.data.searchAllStores
          ? undefined
          : this.storeId;
      } else {
        // If no filter or simple filter - show only current store
        storeId = this.storeId;
      }

      return {
        storeId: storeId,
        ...this.serverPaginationRequest,
        filter: this.parsedFilter.GQLdata,
      };
    },
  },
  watch: {
    // TODO - find a better way to do this
    filter(newFilterValue) {
      this.onPageUpdate(1);
    },
  },
  methods: {
    onShowMore() {
      if (this.isSmallScreen) {
        // When loading data from server, mobile is handled via load more
        let mobileServerPaginationRequest = {};
        ServerPaginationUtils.updatePage(
          this.paginationOptions,
          this.sortOptions,
          mobileServerPaginationRequest,
          this.paginationOptions.page + 1
        );
        ServerPaginationUtils.fetchMoreData(
          this.$apollo.queries,
          "customers",
          "customers",
          {
            ...this.queryVariables,
            ...mobileServerPaginationRequest,
          }
        );
      }
    },
    onPageUpdate(page) {
      ServerPaginationUtils.updatePage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        page
      );
    },
    onItemsPerPageUpdate(itemsPerPage) {
      ServerPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      ServerPaginationUtils.updateSort(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        header.propertyPath
      );
    },
    selectCustomer(customer) {
      this.$router.push({
        name: "customers_show",
        params: { customerId: customer.id },
        // TODO - see how to handle this with tab in query nicer
        query: { customerDetailTab: "info" },
        preserveQuery: true,
      });
    },
  },
};
</script>

<template>
  <VxDataList
    class="customers-list"
    :headers="headers"
    :pagination-options="paginationOptions"
    :sort-options="sortOptions"
    :loading="customersLoading"
    :empty-data-text="'No customers.'"
    :data-loading-text="'Loading customers.'"
    @update:page="onPageUpdate"
    @update:itemsPerPage="onItemsPerPageUpdate"
    @update:sort="onSortUpdate($event)"
    @showMore="onShowMore"
  >
    <template #body>
      <CustomerListItem
        v-for="customer in customers"
        :key="customer.id"
        :customer="customer"
        @show="selectCustomer"
      />
    </template>
  </VxDataList>
</template>

<style lang="scss">
.customers-list {
  .name-cell {
    width: 250px;
  }

  .date-cell {
    width: 200px;
  }

  .phone-cell {
    /* THIS one has auto width */
  }

  .number-buys-cell {
    width: 100px;
  }

  .total-amount-cell {
    width: 200px;
  }
}
</style>
