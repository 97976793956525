<template>
  <span class="d-inline-flex align-center" :class="classes">
    {{ fullName }}
    <v-tooltip v-if="customerFlagged" right>
      <template #activator="{ on }">
        <slot name="icon" :on="on">
          <v-icon :class="classes" small right v-on="on"> $flag </v-icon>
        </slot>
      </template>
      <span>{{ flagReason }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import { get } from "lodash";

export default {
  name: "FlaggedName",
  props: {
    buy: {
      type: Object,
      default: () => {},
    },
    white: {
      type: Boolean,
      default: false,
    },
    darken: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      return {
        "error--text text--darken-2": this.darken && this.hasRedText,
        "error--text": !this.darken && this.hasRedText,
        "white--text": this.hasWhiteText,
      };
    },
    flagOnBuy() {
      return get(this.buy, "customer_flagged", false);
    },
    nameOnBuy() {
      return get(this.buy, "customer_full_name", false);
    },
    // TODO - put this somewhere as helper function!
    customerFlagged() {
      let flagged;
      if (this.customer) {
        flagged =
          (this.customer.hasOwnProperty("customer") &&
            get(this.customer, "customer.flagged", false)) ||
          get(this.customer, "flagged", false);
      } else {
        if (this.flagOnBuy) {
          flagged = get(this.buy, "customer_flagged", false);
        } else {
          flagged = get(this.buy, "customer.flagged", false);
        }
      }
      return flagged;
    },
    flagReason() {
      if (!this.customerFlagged) return;

      return (
        get(this, "customer.flagReason") || // props.customer is a customer
        get(this, "customer.flag_reason") ||
        get(this, "customer.customer.flagReason") || // props.customer is a drsCustomer
        get(this, "customer.customer.flag_eason") ||
        "No reason specified"
      );
    },
    fullName() {
      let name;
      if (this.customer) {
        name = get(this.customer, "full_name", undefined);
        if (name === undefined) {
          name = get(this.customer, "fullName", "");
        }
      } else {
        if (this.nameOnBuy) {
          name = this.buy.customer_full_name;
        } else {
          name = this.buy.customer.full_name;
        }
      }
      return name;
    },
    hasRedText() {
      return this.customerFlagged && !this.hasWhiteText;
    },
    hasWhiteText() {
      return this.white;
    },
  },
};
</script>

<style>
.fa-icon {
  vertical-align: middle;
  margin-left: 0.5em;
}
</style>
