<script>
import { VxDataListItem } from "@/core-ui";
import FlaggedName from "@/components/customers/FlaggedName";
import { formatNumber } from "@/utils/phonenumber";
import { format, parseISO } from "date-fns";

export default {
  name: "CustomerListItem",
  components: {
    VxDataListItem,
    FlaggedName,
  },

  filters: {
    phoneNumber(v) {
      if (!v) return "";
      return formatNumber(v);
    },
    date(v) {
      if (v) {
        if (typeof v === "string") {
          return format(parseISO(v), "MMMM d, yyyy");
        } else {
          return format(v, "MMMM d, yyyy");
        }
      } else {
        return "";
      }
    },
  },
  inheritAttrs: false,
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    // TODO - do this nice to not need to repeat in each component
    showCustomer() {
      this.$emit("show", this.customer);
    },
  },
};
</script>

<template>
  <VxDataListItem
    v-bind="$attrs"
    class="item rai-data"
    v-on="$listeners"
    @click.stop="showCustomer"
  >
    <template #desktop>
      <td class="name-cell">
        <FlaggedName :customer="customer" />
      </td>
      <td class="date-cell">{{ customer.updatedAt | date }}</td>
      <td class="phone-cell">
        <div class="ellipsis-outer">
          <div class="ellipsis-inner">
            {{ customer.phoneNumber | phoneNumber }}
          </div>
        </div>
      </td>
      <td class="text-right number-buys-cell" v-text="customer.numBuys" />
      <td class="text-right total-amount-cell">
        {{ $filters.dollars(customer.totalPurchamt) }}
      </td>
    </template>

    <template #mobile>
      <v-list-item-content
        class="customer-name item__content pt-0 mr-2 flex-shrink-0"
      >
        <v-list-item-title>
          <FlaggedName :customer="customer" class="customer-name-text" />
        </v-list-item-title>
        <v-list-item-subtitle class="font-italic text-caption">
          {{ customer.phoneNumber | phoneNumber }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content class="item__content pt-0">
        <v-list-item-title>{{ customer.numBuys }} </v-list-item-title>
        <v-list-item-subtitle>
          {{ $filters.dollars(customer.totalPurchamt) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action-text
        class="primary--text item__content text-right last-known-date"
      >
        {{ customer.updatedAt | date }}
      </v-list-item-action-text>
    </template>
  </VxDataListItem>
</template>

<style scoped>
.customer-name-text {
  text-overflow: unset;
  word-break: break-word;
  white-space: normal;
}

.customer-name {
  max-width: 12ch;
}

.last-known-date {
  max-width: 12ch;
}

.item {
  padding-top: 8px;
  max-width: 100vw;
}
.item__content {
  align-self: start;
  padding-bottom: 8px;
}
</style>
